.container {
    position: relative;
    height: 100vh;
}
.header {
    background-color: #fff;
    position: relative;
    height: 350px;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    background-image: url(/images/yukon-bg.png);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 60px 5% 80px;
}
.messages {
    background-color: #f5f5f5;
    height: calc(100% - 300px);
    padding: 1rem 0;
    overflow-y: auto;
    z-index: 1;
    scroll-behavior: smooth;
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
    border-radius: 10px;
}
.input {
    position: relative;
    height: 200px;
    z-index: 3;
}



.inputTextField {
    border: 10px;
}

.nadlii_section_large_heading {
    background-image: url(/images/yukon-bg.png);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 60px 5% 80px;
    position: relative;
}

.f_container_regular_heading {
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.f_container_regular {
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.wf_layout_layout {
    grid-template-rows: auto;
    grid-template-columns: .25fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    align-items: stretch;
    grid-auto-columns: 1fr;
    justify-content: center;
    padding: 20px;
    display: grid;
}

.w_layout_cell {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.thumb_img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.f_margin_bottom_24 {
    margin-bottom: 24px;
}


.nadlii_h1_heading {
    color: #333;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Montserrat, sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
}

.nadlii_paragraph_large_subhead {
    margin-bottom: 0;
    font-family: Nunito Sans, sans-serif;
    font-size: 24px;
    font-weight: 200;
    line-height: 32px;
}

.nadlii_searchcircle {
    text-align: center;
    background-color: #dc4405;
    border: 1px solid #aa0707;
    border-radius: 100%;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 150px;
    height: 150px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.6rem;
    display: flex;
    position: absolute;
    top: -25px;
    bottom: auto;
    left: -65px;
    right: auto;
}

.nadlii_search_icon {
    width: 80%;
}

.nadlii_feature_card_outlined_top {
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
    background-color: #f8f8f8;
    border-radius: 10px;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    width: 100%;
    padding: 20px 40px;
    display: block;
}

.nadlii_card_margin_bottom_padleft {
    flex-direction: column;
    flex: 1;
    padding-left: 60px;
    display: flex;
}

.iAmRadioButtons {
    gap: 12px;
}

.prePopSearch {
    margin-top: 16px;
}