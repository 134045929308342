.message {
    padding: .5rem 1rem;
    display: flex;
}
/*
.message:last-child {
    margin-bottom: 1rem;
}
*/
/*
.message + .message {
    border-top: 1px dotted #333;
}
*/
.avatar {
    margin: 0 1rem;
    padding-top: .5rem;
}
.text {
    position: relative;
    min-width: 100px;
    /*max-width: calc(100% - 140px);*/
    max-width: calc(100% - 80px);
    background-color: #3392d1;
    padding: 0 1rem;
    border-radius: .5rem;
    box-sizing: border-box;
    white-space: pre-wrap;
    color: #fff;
    line-height: 1.4rem;
}
.assistant {
    background-color: #fff;
    color: #222;
}
.order1 {
    order: 1;
}
.order2 {
    order: 2;
}
.right {
    justify-content: flex-end;
}
.content {
    position: relative;
}
.progress {
    position: relative;
    height: 1rem;
    margin-top: 12px;
}

.content ul, .content ol {
    margin-block-start: 0em;
    margin-block-end: 0.5em;
    padding-inline-start: 40px;
}

.content p {
    /*margin: 0;*/
    margin: 12px 0;
}

.content h3 {
    margin: 1.5rem 0 0.5em;
}

.content {
    overflow: auto;
    white-space: normal;
    word-wrap: break-word;
    background: none;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    margin: 0;
  }
  
  .content pre {
    white-space: pre-wrap; 
    word-wrap: break-word; 
    background: none;
  }
